import { graphql } from "gatsby";
import React from "react";

function ProductPost({ title, description, data }) {
  return (
    <div>
      <h1>{data?.wpProduct?.title}</h1>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
}
export default ProductPost;
export const query = graphql`
  query ($id: String!) {
    wpProduct(id: {eq: $id }) {
      content
      title
      slug
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`;